import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import LazyLoadImage from '@tb-core/components/composites/lazyload/image';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface SocialMediaProps {
    socialIcons?: LinkProps[];
    title?: string;
}

const SocialMedia = ({ socialIcons = [], title }: SocialMediaProps) => {
    const listItem: ListItem = (
        { gaDataLayerConfig, graphic, url }: LinkProps,
        i
    ) => (
        <SafeAnchorWithGaEvent
            aria-label={graphic?.alt}
            gaDataLayerConfig={gaDataLayerConfig}
            href={url}
            key={i}
        >
            <LazyLoadImage
                alt={graphic?.alt}
                src={graphic?.graphic.src}
                title={graphic?.title}
            />
        </SafeAnchorWithGaEvent>
    );
    return (
        <div className={styles['social-media']}>
            <p>{title}</p>
            <div className={styles['social-icons']}>
                <ItemList item={listItem} itemProps={socialIcons} />
            </div>
        </div>
    );
};

export default SocialMedia;
