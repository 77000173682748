import NavigationLink from '@tb-core/components/composites/navigation-link';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { LayoutNavigationProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

const FooterNavigation = ({ links = [] }: LayoutNavigationProps) => {
    const onClick = (props: LinkProps) => {
        updateDataLayer({
            event: 'menu_navigation',
            link_text: props.label,
            menu_type: 'footer'
        });
    };
    const listItem: ListItem = (props: LinkProps, i) => (
        <NavigationLink
            {...props}
            gaDataLayerConfig={{
                'Analytics-Action': 'Footer',
                'Analytics-Value': props.label
            }}
            key={i}
            onClick={() => onClick(props)}
        />
    );

    return (
        <nav className={`${styles['footer-navigation']} ${styles.navi}`}>
            <ItemList item={listItem} itemProps={links} />
        </nav>
    );
};

export default FooterNavigation;
