import NavigationLink from '@tb-core/components/composites/navigation-link';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { LayoutNavigationProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

const LegalLinks = ({ links = [] }: LayoutNavigationProps) => {
    const listItem: ListItem = (props: LinkProps, i) => (
        <NavigationLink {...props} key={i} />
    );

    return (
        <nav className={`${styles['legal-links']} ${styles.navi}`}>
            <ItemList item={listItem} itemProps={links} />
        </nav>
    );
};

export default LegalLinks;
