import classNames from 'classnames';

import FooterNavigation from '@tb-core/components/composites/footer-navigation';
import LegalLinks from '@tb-core/components/composites/legal-links';
import SocialMedia from '@tb-core/components/composites/social-media';
import ContentfulRichText from '@tb-core/components/simple/contentful-rich-text';
import usePageContext from '@tb-core/hooks/use-page-context';
import { useWebview } from '@tb-public/hooks/use-webview';

import styles from './styles.module.scss';

export interface FooterRegionProps {
    className?: string;
}

const FooterRegion = ({ className }: FooterRegionProps) => {
    const { isWebView } = useWebview();
    const {
        footerCopy,
        footerNavigation,
        legalNavigation,
        socialMedia
    } = usePageContext().content.pageData.layout;

    if (isWebView) {
        return <></>;
    }

    return (
        <footer className={classNames(styles.footer, className)}>
            <section>
                {/* Optional element */}
                <SocialMedia
                    socialIcons={socialMedia?.socialIconsCollection.items}
                    title={socialMedia?.title}
                />

                <FooterNavigation
                    links={footerNavigation?.linksCollection.items}
                />
            </section>

            <hr />

            <section>
                <LegalLinks links={legalNavigation?.linksCollection.items} />
                <ContentfulRichText node={footerCopy.json} />
            </section>
        </footer>
    );
};

export default FooterRegion;
