import FullToolbar from '@tb-core/components/composites/full-toolbar';
import MiniToolbar from '@tb-core/components/composites/mini-toolbar';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';

const StandardToolbar = () => {
    const {
        activateLeftAside,
        activateLoyalty,
        activeLoyalty
    } = useLayoutActivityContext();
    const {
        content: { pageData }
    } = usePageContext();
    const { layout, topicMeta } = pageData;
    const { pageControls } = layout;

    const sharedToolbarProps = {
        activateLoyalty,
        activeLoyalty,
        iconTitleCart: topicMeta.layout?.iconTitleCart,
        iconTitleRewards: topicMeta.layout?.iconTitleRewards,
        pageControls
    };

    return (
        <div>
            <MiniToolbar
                {...sharedToolbarProps}
                activateMenu={activateLeftAside}
                iconTitleLogo={topicMeta.layout?.iconTitleLogo}
                iconTitleMenu={topicMeta.layout?.iconTitleMenu}
                logo={topicMeta.site?.logo.id}
            />

            <FullToolbar
                {...sharedToolbarProps}
                cartLabel={topicMeta.layout?.cartLabel}
            />
        </div>
    );
};

export default StandardToolbar;
