import crypto from 'crypto';

export const shaGenerator = (value: string) => {
    const normalizedValue = value.trim().toLowerCase();

    const sha = crypto
        .createHash('sha256')
        .update(normalizedValue)
        .digest('hex');

    return sha;
};
