import { useEffect } from 'react';

const useServiceWorker = () => {
    const hasServiceWorker =
        typeof navigator !== 'undefined' && 'serviceWorker' in navigator;

    const registerServiceWorker = () => {
        navigator.serviceWorker
            .register('/service-worker.js', { scope: '/' })
            .then(
                registration => {
                    console.info(
                        'Service Worker registration successful with scope: ',
                        registration.scope
                    );
                },
                err => {
                    console.warn('Service Worker registration failed: ', err);
                }
            )
            .catch(console.warn);
    };

    useEffect(() => {
        if (hasServiceWorker) {
            window.addEventListener('load', registerServiceWorker);
        }

        return () => {
            window.removeEventListener('load', registerServiceWorker);
        };
    }, [hasServiceWorker]);
};

export default useServiceWorker;
