import Head from 'next/head';
import React, { ReactNode, useMemo } from 'react';

import { MetaTagProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';
import { dataDogInitialize } from '@tb-core/helpers/analytics/data-dog';
import { metaAttributeAdapter } from '@tb-core/helpers/meta-tags';
import {
    appleItunesId,
    assetPath,
    dataDogEnv,
    googlePlayId
} from '@tb-core/helpers/next-env';
import usePageContext from '@tb-core/hooks/use-page-context';

interface DocumentHeadProps {
    canonicalUrlOverride?: string;
    children?: ReactNode;
    descriptionOverride?: string;
    titleOverride?: string;
}

const DocumentHeadRegion = ({
    canonicalUrlOverride,
    children,
    descriptionOverride,
    titleOverride
}: DocumentHeadProps) => {
    const {
        content: { pageData }
    } = usePageContext();
    const {
        metaTagsCollection,
        seoCanonicalUrl,
        seoDescription,
        seoTitle,
        topicMeta
    } = pageData;

    // Use NPM for Data Dog for init and logs
    if (dataDogEnv) {
        dataDogInitialize(dataDogEnv);
    }

    return useMemo(() => {
        return (
            <Head>
                {/* SEO specific elements */}
                <title>{titleOverride || seoTitle}</title>
                <meta
                    content={descriptionOverride || seoDescription}
                    key="seoDescription"
                    name="description"
                />
                <link
                    href={canonicalUrlOverride || seoCanonicalUrl}
                    key="seoCanonical"
                    rel="canonical"
                />
                {/* End of SEO specific elements */}
                {appleItunesId && (
                    <meta
                        name="apple-itunes-app"
                        content={`app-id=${appleItunesId}`}
                    />
                )}
                {googlePlayId && (
                    <meta
                        name="google-play-app"
                        content={`app-id=${googlePlayId}`}
                    />
                )}
                <meta name="format-detection" content="telephone=no" />
                {metaTagsCollection &&
                    metaTagsCollection?.items.map(
                        (metaTag: MetaTagProps, i: number) => (
                            <meta key={i} {...metaAttributeAdapter(metaTag)} />
                        )
                    )}
                <link
                    rel="shortcut icon"
                    href={`${assetPath}/_static/icons/favicon.ico`}
                />
                <link
                    href={`${assetPath}/_static/styles/global.css`}
                    rel="stylesheet"
                />

                {children}
            </Head>
        );
    }, [
        appleItunesId,
        assetPath,
        canonicalUrlOverride,
        descriptionOverride,
        googlePlayId,
        seoCanonicalUrl,
        seoDescription,
        seoTitle,
        titleOverride,
        topicMeta
    ]);
};

export default DocumentHeadRegion;
