import { track } from '@amplitude/analytics-browser';

import classNames from 'classnames';
import { useMemo, useState } from 'react';

import MainNavigation from '@tb-core/components/composites/main-navigation';
import ProductSearch from '@tb-core/components/container/product-search';
import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import LoginToolbar from '@tb-core/components/styled/login-toolbar';
import LogoAside from '@tb-core/components/styled/logo-aside';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

import styles from './styles.module.scss';

const LeftAsideRegion = () => {
    const [displayUserDropdown, setDisplayUserDropdown] = useState(false);
    const toggleDropdown = () => setDisplayUserDropdown(!displayUserDropdown);

    const {
        content: { pageData }
    } = usePageContext();
    const user = useUserContext();
    const { isLoggedIn } = user;
    const { activateLeftAside, active } = useLayoutActivityContext();
    const {
        layout: { mainNavigation, pageControls },
        topicMeta
    } = pageData;
    const updateLayer = () => {
        updateDataLayer({
            'Analytics-Action': 'Left Nav',
            'Analytics-Value': 'Close'
        });
        activateLeftAside(false);
    };
    // If no Side Menu Links, make Logo not clickable : for Checkout Page.
    const isClickableLogo: boolean = !!mainNavigation?.linksCollection?.items
        ?.length;

    const handleClick = () => {
        if (isClickableLogo) {
            const ampProps = {
                cta: 'Home'
            };
            track('Clicked Nav Menu', ampProps);
        }
    };

    const alertCount = isLoggedIn ? user.offersCount + user.ordersCount : 0;
    const firstName = isLoggedIn ? user.firstName : undefined;

    // @TODO Split out to <Aside><Drawer>{children}</Drawer></Aside>
    return (
        <div className={styles.parent}>
            <aside
                className={classNames(styles.aside, styles.drawer, {
                    [styles.active]: active
                })}
            >
                <section>
                    {useMemo(
                        () => (
                            <LoginToolbar
                                alertCount={alertCount}
                                displayUserDropdown={displayUserDropdown}
                                firstName={firstName}
                                handleClick={handleClick}
                                isClickableLogo={isClickableLogo}
                                isLoggedIn={isLoggedIn}
                                loggedOutHrefYum={
                                    topicMeta.layout?.loggedOutHrefYum
                                }
                                loginClass={styles.login}
                                loginLabel={topicMeta.layout?.loginLabel}
                                logoSvgId={topicMeta.site?.logo.id}
                                toggleDropdown={toggleDropdown}
                                userDropdownMenu={
                                    topicMeta.layout?.userDropdownMenu
                                        ?.dropdownItems
                                }
                            />
                        ),
                        [displayUserDropdown, isLoggedIn]
                    )}

                    {/* Product Search */}
                    {pageControls?.indexOf('Product Search') >= 0 && (
                        <div className={styles.search}>
                            {isLoggedIn && (
                                <LogoAside
                                    handleClick={handleClick}
                                    isClickableLogo={isClickableLogo}
                                    logoSvgId={topicMeta.site?.logo.id}
                                />
                            )}
                            <ProductSearch />
                        </div>
                    )}

                    {/* @TODO Make use of DynamicContent component to be able
                    to load any component that comes through. */}
                    <MainNavigation
                        links={mainNavigation?.linksCollection.items}
                    />
                </section>

                <LazyIconClose
                    className={styles.close}
                    height="50px"
                    onClick={updateLayer}
                    width="50px"
                />
            </aside>
        </div>
    );
};

export default LeftAsideRegion;
