import { track } from '@amplitude/analytics-browser';

import classNames from 'classnames';
import { useCallback } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { optimizelyClient } from '@tb-core/helpers/analytics/optimizely';
import { useOptimizelyFlag } from '@tb-core/hooks/analytics/use-optimizely-flag';
import usePageContext from '@tb-core/hooks/use-page-context';
import { LayoutNavigationProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

const MainNavigation = ({ links = [] }: LayoutNavigationProps) => {
    // optimizely flag enabling for event tracking
    const tbEventsEnabled = useOptimizelyFlag('tb_events');

    const {
        content: { pageData }
    } = usePageContext();

    const handleClick = (label: string) => {
        if (label !== '') {
            const ampProps = {
                cta: label
            };
            track('Clicked Nav Menu', ampProps);
            if (tbEventsEnabled) {
                optimizelyClient.track('clicked_nav_menu');
            }

            updateDataLayer({
                event: 'menu_navigation',
                link_text: label,
                menu_type: 'left nav'
            });
        }
    };

    const listItem: ListItem = ({ label = '', url }: LinkProps, i) => {
        const trackNavClick = useCallback(() => handleClick(label), [label]);

        return url !== pageData.slug ? (
            <SafeAnchorWithGaEvent
                gaDataLayerConfig={{
                    'Analytics-Action': 'Left Nav',
                    'Analytics-Value': label
                }}
                href={url}
                key={i}
                onClick={trackNavClick}
            >
                {label}
            </SafeAnchorWithGaEvent>
        ) : (
            <span className={styles.current} key={i}>
                {label}
            </span>
        );
    };

    return (
        <nav
            className={classNames(
                styles['main-navigation'],
                styles.navi,
                styles['vertical-layout']
            )}
        >
            <ItemList item={listItem} itemProps={links} />
        </nav>
    );
};

export default MainNavigation;
