import { useEffect, useState } from 'react';

import { useParam } from '@tb-core/hooks/window/use-param';

export const useWebview = () => {
    const [param, setKey, noParam] = useParam();
    const [isWebView, setIsWebView] = useState(false);
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    useEffect(() => {
        // Set the key to look for in the path parameters
        setKey('webview=1');
    }, []);

    useEffect(() => {
        // If no parameter was passed, set isLayoutReady to true
        if (noParam === true) {
            setIsWebView(false);
            setIsLayoutReady(true);
        }
    }, [noParam]);

    useEffect(() => {
        if (param) {
            setIsWebView(true);
            setIsLayoutReady(true);
        }
    }, [param]);

    return {
        isLayoutReady,
        isWebView
    };
};
