import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { LinkProps } from '@tb-core/types';

const NavigationLink = ({
    gaDataLayerConfig = {},
    label = '',
    onClick,
    url
}: LinkProps) => (
    <SafeAnchorWithGaEvent
        gaDataLayerConfig={gaDataLayerConfig}
        href={url}
        onClick={onClick}
    >
        {label}
    </SafeAnchorWithGaEvent>
);

export default NavigationLink;
