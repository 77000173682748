import { Fragment, ReactNode } from 'react';

import { RealObject } from '@tb-core/types';

export type ListItem = <T>(
    props: T & ListItemProps & any,
    i: number
) => ReactNode;

export interface ListItemProps {
    index: number;
}

interface ListProps {
    className?: string;
    item: ListItem;
    itemProps: RealObject[];
    template?: RealObject;
}

const ItemList = ({ className, item, itemProps = [], template }: ListProps) => (
    <>
        {itemProps.map((props, i) => (
            <Fragment key={i}>
                {item({ className, template, ...props }, i)}
            </Fragment>
        ))}
    </>
);

export const StructuredDataItemList = ({
    item,
    itemProps = [],
    template
}: ListProps) => (
    <>
        {itemProps.map((props, i) => (
            <Fragment key={i}>{item({ template, ...props }, i)}</Fragment>
        ))}
    </>
);

export default ItemList;
